.home{
  .usa-prose{
    @include u-font('sans', 'md');
    @include u-text('thin');
    @include at-media('tablet') {
      @include u-font('sans', 'lg');
    }
    a{
      @include u-text('no-underline');
      @include u-text('normal');
      @include u-border-bottom('2px', 'solid', 'primary-vivid');
      &:hover{
        @include u-border-bottom('2px', 'solid', 'primary-dark');
      }
      &:visited{
        @include u-border-bottom('2px', 'solid', 'indigo-warm-60v');
      }

    }
  }
}

#elsewhere{
  @include u-padding-y(10);
  .content{
    @include u-font('sans', 'md');
    @include u-text('thin');
    @include at-media('tablet') {
      @include u-font('sans', 'lg');
    }
    ul{
      @include add-list-reset;
      @include u-margin-y(3);
      a{
        @include u-text('no-underline');
        @include u-text('black');
        &:hover{
          span{
            @include u-border-bottom('1px', 'solid', 'black');
          }
        }
      }
    }
  }
}
