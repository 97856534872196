.resume{
  @include u-padding-bottom(10);
  h2{
    @include u-margin-y(5);
    @include u-padding-y(1);
    @include u-text('light');
    @include u-text('uppercase');
    @include u-font('sans', 'sm');
    @include u-border-bottom('1px', 'solid','base-dark');
  }

  .pdf{
    @include u-font('sans', 'xs');
    @include u-text('no-underline');
    @include u-text('primary-vivid');
    span{
      &:hover{
        @include u-border-bottom('1px', 'solid', 'primary-dark');
      }
      &:visited{
        @include u-border-bottom('2px', 'solid', 'indigo-warm-60v');
      }
    }
  }


  .job,
  .school{
    @include u-padding-y(4);
    @include u-text('light');
    @include u-font('sans', 'sm');
    .date{
      @include u-margin-bottom(1);
      @include u-font('sans', 'xs');
      @include u-line-height('sans', 1);
      @include u-padding-y('2px');
      @include u-text('uppercase');
      @include u-display('inline-block');
      span{
        @include u-padding-x('05');
      }
    }
    .title{
      @include u-margin(0);
      @include u-margin-bottom('05');
      @include u-font('sans', 'lg');
      @include u-line-height('sans', 2);
      @include u-text('bold');
    }
    .company{
      @include u-margin(0);
      @include u-margin-bottom('05');
    }
    .location{
      @include u-margin(0);
      @include u-text('italic');
      @include u-line-height('sans', 1);
    }
    .desc{
      @include u-margin-top('205');
      p{
        @include u-text('normal');
        @include u-font('sans', 'sm');
        &:first-child{
          @include u-margin-top(0);
        }
        &:last-child{
          @include u-margin-bottom(0);
        }
      }
    }
    .sites{
      @include add-list-reset;
      @include u-margin-y('105');
      @include u-font('sans', 'xs');
      @include u-text('normal');
      @include at-media('tablet') {
        -moz-column-count: 2;
        -moz-column-gap: 15px;
        -webkit-column-count: 2;
        -webkit-column-gap: 15px;
        column-count: 2;
        column-gap: 15px;
      }
      li{
        @include u-margin-y('05');
        img{
          @include u-margin-right('2px');
          @include u-position('relative');
          @include u-top('2px');
        }
        a{
          @include u-text('no-underline');
          &:hover{
            @include u-text('underline');
          }
        }
      }
    }
  }
}
