.talk-box{
  @include u-padding-y(2);
  // @include u-bg('white');
  h2{
    @include u-margin(0);
    @include u-margin-bottom(1);
  }
  p{
    @include u-margin(0);
    @include u-margin-bottom(1);
  }
}
