
header{
  @include u-margin-bottom(5);
  @include u-padding-y(2);
  @include at-media('tablet') {
    @include u-margin-bottom(5);
    @include u-padding-y(6);
  }
  h1{
    @include u-font('sans', 'md');
    @include at-media('tablet') {
      @include u-font('sans', 'lg');
    }
    @include u-text('normal');
    a{
      @include u-padding-y('05');
      @include u-text('no-underline');
      @include u-text('black');
      &:hover{
        @include u-border-bottom('2px', 'solid', 'primary-vivid');
      }
    }
  }
}
